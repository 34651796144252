<template>
  <div id='successPay'>
    <div class="container">
      <i class="icon icon-success"></i>
      <div class="content">
        <div class="name">更新しました</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'successPay',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="less">
  #successPay{
    padding: 62px 28px;
    width: 100%;
    .container{
      text-align: center;
      margin: 0 auto;
      .icon-success {
        width: 200px;
        height: 200px;
        margin-bottom: 76px;
        background-image: url("~@/assets/images/icon_success_01.png");
      }
    }
  }
</style>
